@import "preset";
@import "~bootstrap/scss/bootstrap";


$planCol: 29;
$planRow: 8;
$planOffset: 2;
$planActive: $yellow-400;
$planSuccess: $teal-400;
$planOpen: $cyan-200;
$--bs-open-rgb: rgb(128, 222, 234);
$planFollow: $pink-300;
$planDanger: $red-500;
$--bs-body-font-size: 0.9rem;


.ex-plan-open {
    background-color: $planOpen;
}

.text-bg-open {
    color: #000!important;
    background-color: $planOpen!important;
}

.ex-plan-active {
    background-color: $planActive;
}            

.text-bg-active {
    color: #000!important;
    background-color: $planActive!important;
}

.ex-plan-success {
    background-color: $planSuccess;
}

.ex-plan-danger {
    background-color: $planDanger;
}

.ex-plan-follow {
    background-color: $planFollow;
}
.text-bg-follow {
    color: #000!important;
    background-color: $planFollow!important;
}

.tableFixHead          { overflow: auto; height: 100px; }
.tableFixHead thead th { position: sticky; top: 0; z-index: 1; }


.exp-planner-grid {

    //background: linear-gradient(90deg, rgba(255,255,255,0) 49.5%, rgba(2,0,36,1) 50%, rgba(255,255,255,0) 50.5%);
    //background-size: calc(100% / calc(23 + 2));
    background: $gray-500;


    .ex-time-grid {
        display: grid;
        grid-template-columns: 4fr repeat(23, 2fr);
        
        margin: 0 !important;
        padding: 0 !important;
        justify-content: center;
        align-items: center;

        background: lightgray;

        .time {
            padding:  0.5rem 0;
            margin:0 !important;
            text-align: center;
            
        }

        .time-past {
            padding:  0.5rem 0;
            margin:0 !important;
            text-align: center;

            background: $red-100;

        }

        .time-present {
            padding:  0.5rem 0;
            margin:0 !important;
            text-align: center;

            background:lightgreen;

        }
    }

    .ex-plan-grid {

        display: grid;
        grid-template-columns: 5fr repeat(calc(23 - 1), 2fr) 1fr;
        grid-template-rows: 100px auto;
        
        margin: 0 !important;
        padding: 0 !important;
        justify-content: center;
        align-items: center;

        font-size: 0.9rem;


        .plan {
            margin: 0.2rem;
            text-align: left;
            padding: 0.3rem;
            cursor: pointer;
            height: 90px;
            border-radius: 0.5rem;
            border: 0.5rem;
            border-bottom: 1px solid #dee2e6 !important;
            border-left: 3px solid $dark !important;
        }            

        .plan-blanc {
            margin: 0 !important;
            padding: 0 !important;            
            background-color: #fff;
            height: 100%;
            border-left: 1px solid #dee2e6 !important;

        }

        .plan-none {
            margin: 0 !important;
            padding: 0 !important;            
            background-color: rgb(242, 241, 241);
            height: 100%;
            border-left: 1px solid #dee2e6 !important;

        }


        .plan-locked {
            margin: 0.3rem 0;

            border-radius: 0.5rem;
            background-color: $gray-400;
            border: 0.5rem;
            text-align: center;
            vertical-align:baseline;
            font-size: 1.2rem;
            height: 90px;

        }        

        .plan-open {

            background-color: $planOpen;


        }
        .plan-active {

            background-color: $planActive;

        }            
        .plan-success {

            background-color: $planSuccess;

        }
        .plan-danger {

            background-color: $planDanger;

        }
        .plan-follow {

            background-color: $planFollow;

        }



    }

    
}

.exp-calendar-grid {

    display: grid;
    //grid-template-rows: repeat(($planCol (+1)), 2fr);
    grid-template-rows:
        [calendar] 1fr
        [vPrimary] 1fr
        [vSecondary] 1fr
        [vCity] 1fr
        [h0800] 1fr
        [h0830] 1fr
		[h0900] 1fr
		[h0930] 1fr
		[h1000] 1fr
		[h1030] 1fr
		[h1100] 1fr
		[h1130] 1fr
		[h1200] 1fr
		[h1230] 1fr
        [nPrimary] 1fr
        [nSecondary] 1fr
        [nCity] 1fr
		[h1300] 1fr
		[h1330] 1fr
		[h1400] 1fr
		[h1430] 1fr
		[h1500] 1fr
		[h1530] 1fr
		[h1600] 1fr
		[h1630] 1fr
		[h1700] 1fr
		[h1730] 1fr
		[h1800] 1fr
		[h1830] 1fr
		[h1900] 1fr
        [h1930];
    grid-template-columns: 1fr repeat($planRow, 5px, minmax(8em, 1fr));
    background: $gray-500;
    font-size: 0.8rem;

    row-gap: 0px;

    margin: 0 !important;
    padding: 0 !important;

        .time {
            margin: 0 !important;
            padding: 0.25rem 0.5rem 0.25rem 0!important;
            text-align: right;
            border-bottom: 1px solid #dee2e6 !important;
            background: white;


        }
 
        .time-past {
            margin: 0 !important;
            padding: 0.25rem 0.5rem 0.25rem 0!important;
            text-align: right;
            border-bottom: 1px solid #457db4 !important;

            background: $red-100;

        }

        .time-present {
            margin: 0 !important;
            padding: 0.25rem 0.5rem 0.25rem 0!important;
            text-align: right;

            background: lightgreen;

        }       

        .plan {
            padding: 0.2rem;
            font-size: 0.85rem;
            text-align: left;
            margin: 0 !important;            
            border: 0.5rem;
            border-bottom: 1px solid #dee2e6 !important;
            border-top: 1px solid #dee2e6 !important;
            border-left: 1px solid #dee2e6 !important;
            border-radius: 5px;
            cursor: pointer;
        } 

        .plan-sbrake {
            border-bottom: 3px dotted #000000 !important;
        }

        .plan-ebrake {
            border-top: 3px dotted #000000 !important;
        }

        .plan-blanc {
            margin: 0 !important;
            padding: 0 !important;                  
            background-color: #fff;
            border-left: 1px solid #dee2e6 !important;
        }

        .plan-none {
            margin: 0 !important;
            padding: 0 !important;            
            vertical-align:baseline;
            border-bottom: 1px solid #d2dce6 !important;

            background-color: rgb(242, 242, 241);
            //border-left: 5px solid #dee2e6 !important;
            font-size: 1.2rem;

        }            
 
        .plan-top {
            margin: 0 !important;
            padding: 0 !important;                  
            border: none;
            background-color: #fff;
            text-align: center;
            font-size: 0.9rem;

            .top-border {
                border-right: 1px solid #dee2e6 !important;
                border-radius: 0px;
            }
        }

        .plan-top.time {
            font-size: 2.3rem;
            padding-top: 20% !important;
            font-weight : bold;
        }
        
        .plan-top-info {
            margin: 0 !important;
            padding: 0 !important;                  
            background-color: #fff;
            border: 1px solid #dee2e6 !important;
            text-align: center;
        }    


        .plan-locked {
            background-color: $gray-400;
            text-align: center;
            vertical-align:baseline;
            font-size: 1.2rem;
        }        

        .plan-open {
            background-color: $planOpen;
        }

        .plan-active, .btn-active {
            background-color: $planActive;
        }
        
        .plan-success {
            background-color: $planSuccess;
        }

        .plan-danger {
            background-color: $planDanger;
        }

        .plan-follow {
            background-color: $planFollow;
        }

        .timeline {
            margin: 0 !important;
            padding: 0 !important;   
            border: 0;

        }
        .timeline.timeline-booked {
            background-color: #4b7af9 !important;
        }
        .timeline.timeline-free {
            background-color: #1bde14 !important;
        }
        .timeline.timeline-time {
            background-color: #ff0000 !important;
        }



}

.doubleDate {
    left: 5% !important;
    width: 80% !important;
    -webkit-animation: blinkerDouble 2s infinite;  /* Safari 4+ */
    -moz-animation: blinkerDouble 2s infinite;  /* Fx 5+ */
    -o-animation: blinkerDouble 2s infinite;  /* Opera 12+ */
    animation: blinkerDouble 2s infinite;  /* IE 10+, Fx 29+ */    
}

.doubleDateZwei {
    left: 10%;
    width: 80% !important;
    -webkit-animation: blinkerDouble 2s infinite;  /* Safari 4+ */
    -moz-animation: blinkerDouble 2s infinite;  /* Fx 5+ */
    -o-animation: blinkerDouble 2s infinite;  /* Opera 12+ */
    animation: blinkerDouble 2s infinite;  /* IE 10+, Fx 29+ */    
}

.doubleDateDrei {
    left: 15%;
    width: 80% !important;
    -webkit-animation: blinkerDouble 2s infinite;  /* Safari 4+ */
    -moz-animation: blinkerDouble 2s infinite;  /* Fx 5+ */
    -o-animation: blinkerDouble 2s infinite;  /* Opera 12+ */
    animation: blinkerDouble 2s infinite;  /* IE 10+, Fx 29+ */    
}

.cell {
    cursor: cell;
}


.pull-right {
    float: right;
}

.navbar-light .navbar-nav, .nav .nav-link {
    color: rgba(0,0,0,.55); 
}

html, body {
    height: 100%;
    font-family: 'Oswald', sans-serif !important;

}

* {
    box-sizing: border-box;
    font-size: 12px;
}

.noneProgressBorderRadius {
    border-radius: unset;
    --bs-progress-border-radius: unset;
}


.signature {
    display: block;
    margin: 5px auto;
    border: 1px solid black;
    width: 400px;
    height: 150px;
  }

.SignButton {
    display: block;
    height: 150px;
    width: 80px;
}

@keyframes blinker {
    50% {
        opacity: 0.5;
    }
}

@keyframes blinkerDouble {
    50% {
        background-color: #f38282;
    }
}
